import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-mda-report.actions';
import { Action } from '../../models';

export interface MdaReportReducerInitialState {
  maxDate: string;
  minDate: string;
  esId: string;
  pcId: string;
  pgId: string;
  tpcId: string;
  mdaId: string;
  toId: string;
}

export const initialState: MdaReportReducerInitialState = {
  maxDate: '',
  minDate: '',
  esId: '',
  pcId: '',
  pgId: '',
  tpcId: '',
  mdaId: '',
  toId: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function mdaReportReducerReducer(
  state: MdaReportReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
