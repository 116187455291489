import {
  Component,
  effect,
  inject,
  Input,
  Output,
  untracked,
  ViewChild,
} from '@angular/core';
import { ObjectLiteral } from '../../core/models/ObjectLiteral';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexNoData,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { CommonModule } from '@angular/common';
import { ChartsService } from '../../core/services/charts.service';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { RouterModule } from '@angular/router';

export type ApexLegendShapes =
  | 'circle'
  | 'square'
  | 'line'
  | 'plus'
  | 'cross'
  | 'star'
  | 'sparkle'
  | 'diamond'
  | 'triangle';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  dataLabels: ApexDataLabels;
  plotOptions?: ApexPlotOptions;
  grid?: ApexGrid;
  stroke: ApexStroke;
  title?: ApexTitleSubtitle;
  legend?: ApexLegend & { markers?: { shape?: ApexLegendShapes } };
  noData?: ApexNoData;
  fill?: ApexFill;
  markers?: ApexMarkers;
  colors?: string[];
  tooltip?: ApexTooltip;
  labels?: string[];
};

@Component({
  selector: 'app-charts',
  standalone: true,
  imports: [NgApexchartsModule, CommonModule, RouterModule],
  templateUrl: './charts.component.html',
  styleUrl: './charts.component.css',
})
export class ChartsComponent {
  chartService = inject(ChartsService);
  //   @Input() chart: ApexChart;
  @Input() annotations: ApexAnnotations;
  @Input() colors: string[];
  @Input() dataLabels: ApexDataLabels;
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  @Input() stroke: ApexStroke;
  @Input() labels: string[];
  @Input() legend: ApexLegend;
  @Input() fill: ApexFill;
  @Input() tooltip: ApexTooltip;
  @Input() plotOptions: ApexPlotOptions;
  @Input() responsive: ApexResponsive[];
  @Input() xaxis: ApexXAxis;
  @Input() yaxis: ApexYAxis | ApexYAxis[];
  @Input() grid: ApexGrid;
  @Input() states: ApexStates;
  @Input() title: ApexTitleSubtitle;
  @Input() subtitle: ApexTitleSubtitle;
  @Input() theme: ApexTheme;
  @Output() chartReady;

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  chartOneQueryResponse = injectQuery(() => {
    return {
      queryKey: ['chart-data-line-one'],
      queryFn: () => this.chartService.getChartData(),
    };
  });

  constructor() {
    this.chartOptions = {
      noData: {
        text: 'Loading...',
      },
      series: [
        {
          name: 'Desktops',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
        title: {
          text: 'Categories',
          style: {
            color: '#FF1654',
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      yaxis: {
        tickAmount: 10,
      },
    };

    //effects
    effect(() => {
      const data = this.chartOneQueryResponse.data();
      untracked(() => {
        console.log('data :>> ', data);
        if (data) {
          this.chart.updateOptions({
            series: [
              {
                data,
              },
            ],
            xaxis: {
              type: 'numeric',
              //   categories: data.map((item) => item.x),
              //   title: {
              //     text: 'Categories',
              //     style: {
              //       color: '#FF1654',
              //     },
              //   },
            },
            dataLabels: {
              enabled: false,
            },
          });
        }
      });
    });
  }
}
