<nz-layout class="h-[100vh]">
  <nz-sider
    nzTheme="light"
    nzCollapsible
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    nzWidth="250px"
    class="border-r"
  >
    <div class="h-[66px] flex items-center pl-[13px]">
      <a class="flex items-center font-medium text-gray-900 title-font md:mb-0">
        <img
          *ngIf="!isCollapsed"
          [src]="'assets/images/logo_with_name.png'"
          class="w-[130px] rounded-full overflow-hidden object-fill"
        />
        <img
          *ngIf="isCollapsed"
          [src]="'assets/images/icon-logo.png'"
          class="w-[50px] rounded-full overflow-hidden object-fill"
        />
      </a>
    </div>

    <ng-scrollbar
      externalViewport
      appearance="compact"
      visibility="hover"
      [ngClass]="{ relative: true }"
    >
      <div scrollViewport [ngStyle]="{ height: 'calc(100vh - 66px)' }">
        <ul
          nz-menu
          nzMode="inline"
          [nzInlineCollapsed]="isCollapsed"
          [ngClass]="{ 'flex flex-col h-full absolute inset-0': true }"
        >
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Dashboard' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/dashboard.svg"></svg-icon>
              <span>Dashboard</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Analytics' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard', 'analytics']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/analytic.svg"></svg-icon>
              <span>Analytics</span>
            </a>
          </li>
          <!-- <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Expert System Models' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard', 'expert-system-models']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/expert-system.svg"></svg-icon>
              <span>Expert System Models</span>
            </a>
          </li> -->
          <li
            nz-submenu
            nzMatchRouterExact
            nzMatchRouter
            [nzTitle]="titleTemplate"
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Reports' : ''"
          >
            <ng-template #titleTemplate>
              <a class="flex gap-3 items-center min-w-[40px] min-h-[40px]">
                <svg-icon src="assets/svgs/menu/report.svg"></svg-icon>
                <span>Reports</span>
              </a>
            </ng-template>

            <ul>
              <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                <a
                  [routerLink]="['/', 'gov_dashboard', 'invoice-reports']"
                  class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                >
                  <svg-icon src="assets/svgs/menu/report.svg"></svg-icon>
                  <span>Invoice Reports</span>
                </a>
              </li>

              <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                <a
                  [routerLink]="['/', 'gov_dashboard', 'collection-reports']"
                  class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                >
                  <svg-icon src="assets/svgs/menu/report.svg"></svg-icon>
                  <span>Collection Reports</span>
                </a>
              </li>

              <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                <a
                  [routerLink]="[
                    '/',
                    'gov_dashboard',
                    'batch-settlement-reports'
                  ]"
                  class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                >
                  <svg-icon src="assets/svgs/menu/report.svg"></svg-icon>
                  <span>Settlement Reports</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'MDAs' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard', 'mda']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/expert-system.svg"></svg-icon>
              <span>MDAs</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Tax Payers' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard', 'tax-payers']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/tax-payer.svg"></svg-icon>
              <span>Tax Payers</span>
            </a>
          </li> -->
          <!-- <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Tax Managers' : ''"
          >
            <a
              [routerLink]="['/', 'gov_dashboard', 'tax-managers']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/tax-manager.svg"></svg-icon>
              <span>Tax Managers</span>
            </a>
          </li> -->
          <!-- <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Enumerators' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'enumerators']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/tax-payer.svg"></svg-icon>
              <span>Enumerators</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'eTCC' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'etcc']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/etcc.svg"></svg-icon>
              <span>eTCC</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'POS Managers' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'pos-managers']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/pos-manager.svg"></svg-icon>
              <span>POS Managers</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Consult Connect' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'consult-connect']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/consult-connect.svg"></svg-icon>
              <span>Consult Connect</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Audit Trail' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'audit-trail']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/audit-trail.svg"></svg-icon>
              <span>Audit Trail</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Tax Support' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'tax-support']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/tax-support.svg"></svg-icon>
              <span>Tax Support</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Profile' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'profile']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/profile.svg"></svg-icon>
              <span>Profile</span>
            </a>
          </li>
          <li
            nz-menu-item
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'CMS' : ''"
          >
            <a
              [routerLink]="['/', 'admin', 'cms']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/cms.svg"></svg-icon>
              <span>CMS</span>
            </a>
          </li> -->

          <li
            nz-menu-item
            nzDanger
            nzMatchRouterExact
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Log Out' : ''"
            [ngClass]="{ '!mt-auto': true }"
          >
            <a
              [routerLink]="['/']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon src="assets/svgs/menu/log-out.svg"></svg-icon>
              <span>Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </ng-scrollbar>
  </nz-sider>

  <nz-layout>
    <nz-header class="border-b h-[66px]">
      <app-admin-header></app-admin-header>
    </nz-header>

    <nz-content class="p-10 overflow-y-auto">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
