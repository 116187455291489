import { CommonModule, Location } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AuthService } from '../../../core/services/auth.service';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { LocalService } from '../../../core/services/local.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import dayjs from 'dayjs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { setStateAction } from '../../../core/store/actions/settings.actions';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../../core/store/selectors/settings.selectors';

const menuList = [
  {
    name: 'Profile',
    link: '/admin/profile',
    type: 'All',
    exact: false,
    icon: 'assets/navMenu/paye-manager-icon.svg',
  },
  {
    name: 'Service Logs',
    link: 'admin/logs',
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/log-out.svg',
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
  },
];

@Component({
  selector: 'app-admin-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    OutsideClickDirective,
    NzAvatarModule,
    NzDropDownModule,
    NzInputModule,
    NzButtonModule,
  ],
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.css',
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);
  localService = inject(LocalService);
  route = inject(ActivatedRoute);
  location = inject(Location);
  authService = inject(AuthService);
  router = inject(Router);

  settingsStoreSub: Subscription;

  showPopover = false;
  sideMenuCollapsedState = false;
  menuList = signal(menuList);

  get header() {
    const pathname = this.location.path();
    switch (pathname) {
      case '/sadmin':
        return 'Dashboard';
      case '/sadmin/settings':
        return 'Settings';

      default:
        return 'Dashboard';
    }
  }

  get userData() {
    return this.localService.getData('pay_kd_user');
  }

  get abbr() {
    const firstName = (this.userData?.firstName as string) || '';
    const lastName = (this.userData?.lastName as string) || '';
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
  }

  get todayDate() {
    return dayjs().format('DD/MM/YYYY');
  }

  toggleSideMenuCollapsedState() {
    this.store.dispatch(
      setStateAction({ sideMenuCollapsedState: !this.sideMenuCollapsedState })
    );
  }

  clickStatusHandler(status: any) {
    if (!status) {
      this.showPopover = false;
    }
  }

  popoverHandler() {
    this.showPopover = !this.showPopover;
  }

  //   logoutHandler() {
  //     this.localService.clearData();
  //     this.router.navigateByUrl('/', { replaceUrl: true });
  //   }
  logoutHandler(name: string, path: string) {
    this.router.navigateByUrl(path, {
      replaceUrl: true,
    });
    if (name === 'Log Out') {
      this.authService.clearUserData();
    }
  }

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.sideMenuCollapsedState = settingsStore.sideMenuCollapsedState;
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
