import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminHeaderComponent } from '../../shared/widgets/admin-header/admin-header.component';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/store';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../core/store/selectors/settings.selectors';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'app-governor-layout',
  standalone: true,
  imports: [
    RouterModule,
    AdminHeaderComponent,
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    SvgIconComponent,
    NgScrollbarModule,
    NzToolTipModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css',
})
export class GovernorLayoutComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);

  settingsStoreSub: Subscription;

  isCollapsed = false;

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.isCollapsed = settingsStore.sideMenuCollapsedState;
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
