import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilKeyChanged,
  finalize,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AdminInvoiceReportService } from '../services/admin-invoice-report.service';
import {
  resetListQueryAction,
  setDocAction,
  setListAction,
  setListQueryAction,
  setSearchAction,
  setStateAction,
  setCardsAction,
} from '../store/actions/admin-invoice-report.actions';
import { getAdminInvoiceReportStore } from '../store/selectors/admin-invoice-report.selectors';
import { errorToastAction } from '../store/actions/toast.actions';

@Injectable()
export class AdminInvoiceReportEffects {
  constructor(
    private actions$: Actions,
    private adminInvoiceReportService: AdminInvoiceReportService,
    private store: Store
  ) {}

  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListAction),
      withLatestFrom(this.store.pipe(select(getAdminInvoiceReportStore))),
      switchMap(([payload, reducerStore]) => {
        // set listLoading to true
        this.store.dispatch(setStateAction({ listLoading: true }));

        return this.adminInvoiceReportService
          .getAll({
            pageNumber: reducerStore.page,
            pageSize: reducerStore.size,
            search: reducerStore.search,
            sortField: reducerStore.sortField,
            sortOrder: reducerStore.sortOrder,
            RHId: reducerStore.rhId,
            Status: reducerStore.status,
            MinDate: !!reducerStore.minDate
              ? reducerStore.minDate
              : '1990-01-01',
            MaxDate: !!reducerStore.maxDate
              ? reducerStore.maxDate
              : '2090-01-01',
          })
          .pipe(
            map((response) =>
              setStateAction({
                total: response.totalRecords,
                list: response.data,
              })
            ),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while fetching users data',
                })
              )
            ),
            finalize(() =>
              this.store.dispatch(
                setStateAction({
                  listLoading: false,
                })
              )
            )
          );
      })
    )
  );

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListQueryAction),
      map((payload) => this.store.dispatch(setStateAction({ ...payload }))),
      switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetListQueryAction),
      map((payload) =>
        this.store.dispatch(
          setStateAction({
            page: 1,
            sortOrder: '',
            sortField: '',
            search: '',
            rhId: '',
            status: '',
            minDate: '',
            maxDate: '',
          })
        )
      ),
      switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );

  setSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({ search: payload.keyword, page: 1 })
        );
        return payload;
      }),
      debounceTime(300),
      distinctUntilKeyChanged('keyword'),
      map(() => {
        return setListAction(null);
      })
    )
  );

  loadDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDocAction),
      switchMap((action) => {
        // set docLoading to true
        this.store.dispatch(setStateAction({ docLoading: true, doc: null }));

        return this.adminInvoiceReportService.getOne({ id: action.id }).pipe(
          map((response) =>
            setStateAction({
              doc: response,
            })
          ),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching user data',
              })
            )
          ),
          finalize(() =>
            this.store.dispatch(setStateAction({ docLoading: false }))
          )
        );
      })
    )
  );

  loadCards$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setCardsAction),
        withLatestFrom(this.store.pipe(select(getAdminInvoiceReportStore))),
        switchMap(([action, adminInvoiceReportStore]) => {
          // set cardsLoading to true
          this.store.dispatch(setStateAction({ cardsLoading: true }));

          return this.adminInvoiceReportService
            .getCards({
              search: adminInvoiceReportStore.search,
              MaxDate: adminInvoiceReportStore.maxDate
                ? adminInvoiceReportStore.maxDate
                : '2090-01-01',
              MinDate: adminInvoiceReportStore.minDate
                ? adminInvoiceReportStore.minDate
                : '1990-01-01',
              RHId: adminInvoiceReportStore.rhId
                ? adminInvoiceReportStore.rhId
                : '-',
              Status: adminInvoiceReportStore.status
                ? adminInvoiceReportStore.status
                : '-',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  cards: { ...response },
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin invoice report card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    cardsLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );
}
