import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  delay,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AdminDashboardService } from '../services/admin-dashboard.service';
import {
  setStatAction,
  setQueryAction,
  setStateAction,
  resetQueryAction,
} from '../store/actions/admin-dashboard.actions';
import { getAdminDashboardStore } from '../store/selectors/admin-dashboard.selectors';
import { errorToastAction } from '../store/actions/toast.actions';
import { injectQueryClient } from '@tanstack/angular-query-experimental';

@Injectable()
export class AdminDashboardEffects {
  constructor(
    private actions$: Actions,
    private adminDashboardService: AdminDashboardService,
    private store: Store
  ) {}
  queryClient = injectQueryClient();

  loadStat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setStatAction),
      withLatestFrom(this.store.pipe(select(getAdminDashboardStore))),
      switchMap(([action, reducerStore]) => {
        // set cardsLoading to true
        this.store.dispatch(setStateAction({ cardsLoading: true }));

        return this.adminDashboardService
          .getCards({
            MaxDate: !!reducerStore.maxDate
              ? reducerStore.maxDate
              : '2090-01-01',
            MinDate: !!reducerStore.minDate
              ? reducerStore.minDate
              : '1990-01-01',
            ESId: !!reducerStore.esId ? reducerStore.esId : '-',
            PGId: !!reducerStore.pgId ? reducerStore.pgId : '-',
            PCId: !!reducerStore.pcId ? reducerStore.pcId : '-',
            TaxPayerCategory: !!reducerStore.tpcId ? reducerStore.tpcId : '-',
            MDAId: !!reducerStore.mdaId ? reducerStore.mdaId : '-',
            TOId: !!reducerStore.toId ? reducerStore.toId : '-',
          })
          .pipe(
            map((response) =>
              setStateAction({
                cards: response,
              })
            ),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while fetching admin dashboard stat data',
                })
              )
            ),
            finalize(() =>
              this.store.dispatch(
                setStateAction({
                  cardsLoading: false,
                })
              )
            )
          );
      })
    )
  );

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => {
        this.store.dispatch(setStateAction({ ...payload }));

        return { type: 'Success', successAction: payload.successAction };
      }),
      delay(100),
      tap(({ successAction }) => {
        if (successAction) {
          successAction(this.queryClient);
        }
      })
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({
            esId: '',
            pcId: '',
            pgId: '',
            tpcId: '',
            toId: '',
            mdaId: '',
            minDate: '',
            maxDate: '',
          })
        );

        return { type: 'Success', successAction: payload.successAction };
      }),
      delay(100),
      tap(({ successAction }) => {
        if (successAction) {
          successAction(this.queryClient);
        }
      })
    )
  );
}
