// import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AuthComponent } from '../pages/auth/auth.component';
import {
  authAdminGuard,
  authAgentGuard,
  authSuperAdminGuard,
  authUserGuard,
  govGuard,
} from '../shared/auth.guard';
import { SignupComponent } from '../pages/auth/signup/signup.component';
import { EmailVerificationNoticeComponent } from '../pages/auth/email-verification-notice/email-verification-notice.component';
import { EmailVerifiedComponent } from '../pages/auth/email-verified/email-verified.component';
import { DashboardComponent } from '../pages/user-protected/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../pages/auth/reset-password/reset-password.component';
import { ComingSoonComponent } from '../shared/widgets/coming-soon/coming-soon.component';
import { AgentFileUploadComponent } from '../pages/agent-file-upload/agent-file-upload.component';
import { RoleManagementComponent } from '../pages/role-management/role-management.component';
import { AdminLayoutComponent } from '../pages/admin/admin.component';
import { PublicComponent } from '../pages/public/public.component';
import { PaymentSummaryComponent } from '../pages/user-protected/payment-summary/payment-summary.component';
import { ChartsComponent } from '../pages/charts/charts.component';
import { ProtectedLayoutComponent } from '../shared/widgets/protected-layout/protected-layout.component';
import { GovernorLayoutComponent } from '../pages/governor_dashboard/admin.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    component: PublicComponent,
    loadChildren: () =>
      import('../pages/public/public.route').then(
        (route) => route.PUBLIC_ROUTES
      ),
  },
  {
    path: 'auth',
    component: AuthComponent,
    // redirectTo: '/login',
    // pathMatch: 'full',
    loadChildren: () =>
      import('../pages/auth/auth.route').then((route) => route.AUTH_ROUTES),
  },
  {
    path: 'user',
    component: ProtectedLayoutComponent,
    loadChildren: () =>
      import('../pages/user-protected/user-protected.route').then(
        (route) => route.USER_PROTECTED_ROUTES
      ),
    canActivate: [authUserGuard],
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'payment_summary',
    component: PaymentSummaryComponent,
  },
  {
    path: 'email-notice',
    component: EmailVerificationNoticeComponent,
  },
  {
    path: 'verify_email',
    component: EmailVerifiedComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    canActivate: [authSuperAdminGuard],
  },
  {
    path: 'uploads',
    component: AgentFileUploadComponent,
    // canActivate: [authAgentGuard],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('../pages/admin/admin.route').then((route) => route.ADMIN_ROUTES),
    canActivate: [authAdminGuard],
  },
  {
    path: 'gov_dashboard',
    component: GovernorLayoutComponent,
    loadChildren: () =>
      import('../pages/governor_dashboard/governor.route').then(
        (route) => route.GOVERNOR_ROUTES
      ),
    canActivate: [govGuard],
  },
  {
    path: 'charts',
    component: ChartsComponent,
    loadChildren: () =>
      import('../pages/charts/charts.route').then(
        (route) => route.CHART_ROUTES
      ),
  },
];

export class AppRoutingModule {}
