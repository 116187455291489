import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilKeyChanged,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AdminAnalyticsService } from '../services/admin-analytics.service';
import {
  resetQueryAction,
  setQueryAction,
  setSearchAction,
  setStateAction,
  setLgaCardAction,
  setPercentagePaidUnpaidCardAction,
  setTotalGeneratedRevenueCardAction,
  setTotalInvoicePaidByGatewayCardAction,
  setPercentageInvoicePaidByMonthCardAction,
  setPercentageRevenueCollectedByTaxPayerCategoryCardAction,
  setTotalInvoicePaidByBankCardAction,
  setPercentageInvoicePaidByTop5MvaCardAction,
  setPercentageInvoicePaidByBottom5MvaCardAction,
  setPercentageInvoicePaidByTop3MdaCardAction,
  setPercentageInvoicePaidByBottom3MdaCardAction,
} from '../store/actions/admin-analytics.actions';
import { getAdminAnalyticsStore } from '../store/selectors/admin-analytics.selectors';
import { errorToastAction } from '../store/actions/toast.actions';
import { getAdminDashboardStore } from '../store/selectors/admin-dashboard.selectors';
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import dayjs from 'dayjs';

@Injectable()
export class AdminAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private adminAnalyticsService: AdminAnalyticsService,
    private store: Store
  ) {}

  queryClient = injectQueryClient();

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => {
        this.store.dispatch(setStateAction({ ...payload }));
        return { type: 'Success', successAction: payload.successAction };
      }),
      delay(100),
      tap(({ successAction }) => {
        if (successAction) {
          successAction(this.queryClient);
        }
      })
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({
            esId: '',
            pcId: '',
            pgId: '',
            tpcId: '',
            toId: '',
            mdaId: '',
            minDate: '',
            maxDate: '',
          })
        );
        return { type: 'Success', successAction: payload.successAction };
      }),
      delay(100),
      tap(({ successAction }) => {
        if (successAction) {
          successAction(this.queryClient);
        }
      })
    )
  );

  setSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchAction),
      map((payload) => {
        this.store.dispatch(setStateAction({ search: payload.keyword }));
        return payload;
      }),
      debounceTime(300),
      distinctUntilKeyChanged('keyword'),
      map(() => {
        return setLgaCardAction(null);
      })
    )
  );

  loadLgaCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setLgaCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set lgaCardLoading to true
          this.store.dispatch(setStateAction({ lgaCardLoading: true }));

          return this.adminAnalyticsService
            .getLgaCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  lgaCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin lga analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    lgaCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentagePaidUnpaidCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentagePaidUnpaidCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentagePaidUnpaidCardLoading to true
          this.store.dispatch(
            setStateAction({ percentagePaidUnpaidCardLoading: true })
          );

          return this.adminAnalyticsService
            .getPercentagePaidUnpaidCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentagePaidUnpaidCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentagePaidUnpaid analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentagePaidUnpaidCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadTotalInvoicePaidByGatewayCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTotalInvoicePaidByGatewayCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set totalInvoicePaidByGatewayCardLoading to true
          this.store.dispatch(
            setStateAction({ totalInvoicePaidByGatewayCardLoading: true })
          );

          return this.adminAnalyticsService
            .getTotalInvoicePaidByGatewayCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  totalInvoicePaidByGatewayCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin total invoice paid by gateway analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    totalInvoicePaidByGatewayCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadTotalGeneratedRevenueCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTotalGeneratedRevenueCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set totalGeneratedRevenueCardLoading to true
          this.store.dispatch(
            setStateAction({ totalGeneratedRevenueCardLoading: true })
          );

          return this.adminAnalyticsService
            .getTotalGeneratedRevenueCard({
              yr: dayjs().year(),
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  totalGeneratedRevenueCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin totalGeneratedRevenue analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    totalGeneratedRevenueCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadTotalInvoicePaidByBankCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTotalInvoicePaidByBankCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set totalInvoicePaidByBankCardLoading to true
          this.store.dispatch(
            setStateAction({ totalInvoicePaidByBankCardLoading: true })
          );

          return this.adminAnalyticsService
            .getTotalInvoicePaidByBankCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  totalInvoicePaidByBankCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin totalInvoicePaidByBank analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    totalInvoicePaidByBankCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageInvoicePaidByMonthCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageInvoicePaidByMonthCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageInvoicePaidByMonthCardLoading to true
          this.store.dispatch(
            setStateAction({ percentageInvoicePaidByMonthCardLoading: true })
          );

          return this.adminAnalyticsService
            .getPercentageInvoicePaidByMonthCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageInvoicePaidByMonthCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageInvoicePaidByMonth analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageInvoicePaidByMonthCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageRevenueCollectedByTaxPayerCategoryCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageRevenueCollectedByTaxPayerCategoryCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageRevenueCollectedByTaxPayerCategoryCardLoading to true
          this.store.dispatch(
            setStateAction({
              percentageRevenueCollectedByTaxPayerCategoryCardLoading: true,
            })
          );

          return this.adminAnalyticsService
            .getPercentageRevenueCollectedByTaxPayerCategoryCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageRevenueCollectedByTaxPayerCategoryCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageRevenueCollectedByTaxPayerCategory analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageRevenueCollectedByTaxPayerCategoryCardLoading:
                      false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageInvoicePaidByTop5MvaCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageInvoicePaidByTop5MvaCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageInvoicePaidByTop5MvaCardLoading to true
          this.store.dispatch(
            setStateAction({ percentageInvoicePaidByTop5MvaCardLoading: true })
          );

          return this.adminAnalyticsService
            .getPercentageInvoicePaidByTop5MvaCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageInvoicePaidByTop5MvaCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageInvoicePaidByTop5Mva analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageInvoicePaidByTop5MvaCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageInvoicePaidByBottom5MvaCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageInvoicePaidByBottom5MvaCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageInvoicePaidByBottom5MvaCardLoading to true
          this.store.dispatch(
            setStateAction({
              percentageInvoicePaidByBottom5MvaCardLoading: true,
            })
          );

          return this.adminAnalyticsService
            .getPercentageInvoicePaidByBottom5MvaCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageInvoicePaidByBottom5MvaCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageInvoicePaidByBottom5Mva analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageInvoicePaidByBottom5MvaCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageInvoicePaidByTop3MdaCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageInvoicePaidByTop3MdaCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageInvoicePaidByTop3MdaCardLoading to true
          this.store.dispatch(
            setStateAction({ percentageInvoicePaidByTop3MdaCardLoading: true })
          );

          return this.adminAnalyticsService
            .getPercentageInvoicePaidByTop3MdaCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageInvoicePaidByTop3MdaCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageInvoicePaidByTop3Mda analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageInvoicePaidByTop3MdaCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );

  loadPercentageInvoicePaidByBottom3MdaCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPercentageInvoicePaidByBottom3MdaCardAction),
        withLatestFrom(
          this.store.pipe(select(getAdminDashboardStore)),
          this.store.pipe(select(getAdminAnalyticsStore))
        ),
        switchMap(([action, adminDashboardStore, adminAnalyticsStore]) => {
          // set percentageInvoicePaidByBottom3MdaCardLoading to true
          this.store.dispatch(
            setStateAction({
              percentageInvoicePaidByBottom3MdaCardLoading: true,
            })
          );

          return this.adminAnalyticsService
            .getPercentageInvoicePaidByBottom3MdaCard({
              search: adminAnalyticsStore.search,
              MaxDate: adminDashboardStore.maxDate
                ? adminDashboardStore.maxDate
                : '2090-01-01',
              MinDate: adminDashboardStore.minDate
                ? adminDashboardStore.minDate
                : '1990-01-01',
              ESId: adminDashboardStore.esId ? adminDashboardStore.esId : '13',
              PGId: adminDashboardStore.pgId ? adminDashboardStore.pgId : '4',
              PCId: adminDashboardStore.pcId ? adminDashboardStore.pcId : '1',
              TaxPayerCategory: adminDashboardStore.tpcId
                ? adminDashboardStore.tpcId
                : 'All',
              MDAId: adminDashboardStore.mdaId
                ? adminDashboardStore.mdaId
                : '614',
            })
            .pipe(
              map((response) => {
                return setStateAction({
                  percentageInvoicePaidByBottom3MdaCard: response,
                });
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while fetching admin percentageInvoicePaidByBottom3Mda analytics card data',
                  })
                )
              ),
              finalize(() =>
                this.store.dispatch(
                  setStateAction({
                    percentageInvoicePaidByBottom3MdaCardLoading: false,
                  })
                )
              )
            );
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ),
    { dispatch: true }
  );
}
