import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';

const menuList = [
  {
    name: 'Dashboard',
    link: ['/', 'admin'],
    type: 'All',
    exact: true,
    icon: 'assets/svgs/menu/dashboard.svg',
  },
  {
    name: 'Analytics',
    link: ['/', 'admin', 'analytics'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/analytic.svg',
  },
  {
    name: 'Expert System Models',
    link: ['/', 'admin', 'expert-system-models'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/expert-system.svg',
  },
  {
    name: 'Reports',
    subMenuList: [
      {
        name: 'Invoice Reports',
        link: ['/', 'admin', 'invoice-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
      {
        name: 'Collection Reports',
        link: ['/', 'admin', 'collection-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
      {
        name: 'Settlement Reports',
        link: ['/', 'admin', 'settlement-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
    ],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/report.svg',
  },
  {
    name: 'MDAs',
    link: ['/', 'admin', 'mda-reports'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/expert-system.svg',
  },
  {
    name: 'Tax Payers',
    link: ['/', 'admin', 'tax-payers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-payer.svg',
  },
  {
    name: 'Tax Managers',
    link: ['/', 'admin', 'tax-managers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-manager.svg',
  },
  {
    name: 'Enumerators',
    link: ['/', 'admin', 'enumerators'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-payer.svg',
  },
  {
    name: 'eTCC',
    link: ['/', 'admin', 'etcc'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/etcc.svg',
  },
  {
    name: 'POS Managers',
    link: ['/', 'admin', 'pos-managers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/pos-manager.svg',
  },
  {
    name: 'Consult Connect',
    link: ['/', 'admin', 'consult-connect'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/consult-connect.svg',
  },
  {
    name: 'Audit Trail',
    link: ['/', 'admin', 'audit-trail'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/audit-trail.svg',
  },
  {
    name: 'Tax Support',
    link: ['/', 'admin', 'tax-support'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-support.svg',
  },
  {
    name: 'Profile',
    link: ['/', 'admin', 'profile'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/profile.svg',
  },
  {
    name: 'CMS',
    link: ['/', 'admin', 'cms'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/cms.svg',
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
  },
];

@Component({
  selector: 'app-admin-sidemenu',
  standalone: true,
  imports: [
    SvgIconComponent,
    NzToolTipModule,
    RouterModule,
    NgScrollbarModule,
    CommonModule,
    NzMenuModule,
  ],
  templateUrl: './admin-sidemenu.component.html',
  styleUrl: './admin-sidemenu.component.css',
})
export class AdminSidemenuComponent {
  @Input({ required: true }) isCollapsed = false;

  menuList = signal(menuList);
}
