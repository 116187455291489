import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-analytics.actions';
import { Action } from '../../models';

export interface AnalyticsReducerInitialState {
  // data
  lgaCardLoading: boolean;
  lgaCard: any;
  percentagePaidUnpaidCardLoading: boolean;
  percentagePaidUnpaidCard: any;
  totalGeneratedRevenueCardLoading: boolean;
  totalGeneratedRevenueCard: any;
  totalInvoicePaidByGatewayCardLoading: boolean;
  totalInvoicePaidByGatewayCard: any;
  percentageInvoicePaidByMonthCardLoading: boolean;
  percentageInvoicePaidByMonthCard: any;
  percentageRevenueCollectedByTaxPayerCategoryCardLoading: boolean;
  percentageRevenueCollectedByTaxPayerCategoryCard: any;
  totalInvoicePaidByBankCardLoading: boolean;
  totalInvoicePaidByBankCard: any;
  percentageInvoicePaidByTop5MvaCardLoading: boolean;
  percentageInvoicePaidByTop5MvaCard: any;
  percentageInvoicePaidByBottom5MvaCardLoading: boolean;
  percentageInvoicePaidByBottom5MvaCard: any;
  percentageInvoicePaidByTop3MdaCardLoading: boolean;
  percentageInvoicePaidByTop3MdaCard: any;
  percentageInvoicePaidByBottom3MdaCardLoading: boolean;
  percentageInvoicePaidByBottom3MdaCard: any;

  // filters
  search: string;
  maxDate: string;
  minDate: string;
  esId: string;
  pcId: string;
  pgId: string;
  tpcId: string;
  mdaId: string;
  toId: string;
}

export const initialState: AnalyticsReducerInitialState = {
  search: '',
  maxDate: '',
  minDate: '',
  esId: '',
  pcId: '',
  pgId: '',
  tpcId: '',
  mdaId: '',
  toId: '',
  lgaCardLoading: false,
  lgaCard: null,
  percentagePaidUnpaidCardLoading: false,
  percentagePaidUnpaidCard: null,
  totalGeneratedRevenueCardLoading: false,
  totalGeneratedRevenueCard: null,
  totalInvoicePaidByGatewayCardLoading: false,
  totalInvoicePaidByGatewayCard: null,
  percentageInvoicePaidByMonthCardLoading: false,
  percentageInvoicePaidByMonthCard: null,
  percentageRevenueCollectedByTaxPayerCategoryCardLoading: false,
  percentageRevenueCollectedByTaxPayerCategoryCard: null,
  totalInvoicePaidByBankCardLoading: false,
  totalInvoicePaidByBankCard: null,
  percentageInvoicePaidByTop5MvaCardLoading: false,
  percentageInvoicePaidByTop5MvaCard: null,
  percentageInvoicePaidByBottom5MvaCardLoading: false,
  percentageInvoicePaidByBottom5MvaCard: null,
  percentageInvoicePaidByTop3MdaCardLoading: false,
  percentageInvoicePaidByTop3MdaCard: null,
  percentageInvoicePaidByBottom3MdaCardLoading: false,
  percentageInvoicePaidByBottom3MdaCard: null,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function analyticsReducerReducer(
  state: AnalyticsReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
