import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalService } from '../core/services/local.service';

export const parseUserDataFromLocalStore = () => {
  const localService = inject(LocalService);
  const userData = localService.getData('pay_kd_user');
  return {
    role: userData?.roles?.[0],
    isLoggedIn: !!userData,
  };
};

export const authUserGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();

  const router = inject(Router);
  const roles = ['Individual', 'Agent'];

  const isIndividualOrAgent = roles.includes(role);

  if (isLoggedIn && isIndividualOrAgent) {
    return true;
  }
  router.navigate(['/'], { replaceUrl: true });
  return false;
};

export const authAgentGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const router = inject(Router);

  const isAgent = ['Agent', 'agent'].includes(role);

  if (isLoggedIn && !isAgent) {
    router.navigate(['/user/dashboard'], { replaceUrl: true });
    return false;
  }

  if (isAgent && isLoggedIn) {
    return true;
  } else {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }
};

export const authAdminGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const router = inject(Router);

  const isAdmin = ['Admin', 'Administrator', 'SuperAdmin'].includes(role);

  if (isAdmin && isLoggedIn) {
    return true;
  } else {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }
};

export const authSuperAdminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  console.log('role :>> ', role);

  const isSuperAdmin = role === 'SuperAdmin';

  console.log('isSuperAdmin :>> ', isSuperAdmin);
  //   return true;
  if (isSuperAdmin && isLoggedIn) {
    return true;
  } else {
    router.navigate(['/']);
    return false;
  }
};

export const govGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  console.log('role :>> ', role);

  const isGovernor = role === 'Governor';

  console.log('isGovernor :>> ', isGovernor);
  //   return true;
  if (isGovernor && isLoggedIn) {
    return true;
  } else {
    router.navigate(['/']);
    return false;
  }
};
