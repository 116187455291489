import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/invoice.actions';
import { Action } from '../../models';

export interface InvoiceReducerInitialState {
  modalState: boolean;
}

export const initialState: InvoiceReducerInitialState = {
  modalState: false,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function invoiceReducer(
  state: InvoiceReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
